
body {
  margin: 0;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: flex-start; 
  padding-top: 10vh; 
  background-color: #f0f0f0; 
  overflow-y: auto; 
}

.navbar {
  width: 100%; 
  background-color: red; 
  display: flex; 
  justify-content: center;
  padding: 10px 0; 
  position: absolute; 
  top: 0; 
  left: 0; 
  z-index: 1000; 
}

.logo img {
  height: 50px; 
}

.form-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%; 
  max-width: 400px; 
  align-items: stretch;
  margin-top: 20px; 
  margin-bottom: 20px; 
}

label {
  margin-bottom: 10px; 
  padding: 0; 
}

input[type="number"] {
  width: 100%; 
  padding: 8px; 
  margin-top: 5px; 
  margin-bottom: 0; 
  border: 1px solid #ccc;
  border-radius: 4px;
  font-weight: bold;
  transition: border-color 0.3s; 
  box-sizing: border-box; 
}

input[type="number"]:focus {
  border-color: red;
  outline: none; 
}

.result-container {
  margin-top: 20px; 
}

button {
  padding: 12px; 
  background-color: #ff0000; 
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bolder;
  transition: background-color 0.3s; 
  width: 100%; 
  margin-top: 10px; 
}

button:hover {
  background-color: #cc0000; 
}


@media (max-width: 1024px) {
  .form-container {
    max-width: 350px; 
  }

  input[type="number"], button {
    padding: 10px; 
  }

  h1 {
    font-size: 24px; 
  }
}


@media (max-width: 768px) {
  body {
    padding-top: 5vh; 
  }
  
  .form-container {
    margin-top: 10px;
    max-width: 300px; 

  }

  button {
    padding: 8px; 
    font-size: 14px; 
  }

  h1 {
    font-size: 20px; 
  }
}


@media (max-width: 480px) {
  .form-container {
    padding: 15px; 
    max-width: 90%; 
  }

  input[type="number"], button {
    padding: 8px; 
  }

  h1 {
    font-size: 18px; 
  }
}
